<template >
  <div>
    <el-dialog
      :title="title"
      :visible.sync="kuanflag"
      :before-close="layerClose"
      width="1000px"
    >
   <div class="formTable">
       <el-form :model="fromArr" :inline="true" label-position="right" label-width="80px" bordered>
              <el-form-item label="角色名称：">
                  <el-input v-model="fromArr.name" clearable  style="width:200px" placeholder="请输入角色名称"></el-input>
                 
                </el-form-item>
                 <el-form-item label="角色备注：">
                  <el-input type="areatext" v-model="fromArr.remark" clearable  style="width:580px" placeholder="请输入角色备注"></el-input>
                 
                </el-form-item>
       </el-form>
        <div style="border-bottom: 1px solid #eee">
          <div class="title">权限设置：（勾选权限选择框，代表有权限）</div>
           <div v-for="(itemes, index) in permissions">
            
                <div class="rowboxs">
                    <div class="threeOne">
                       <el-checkbox :label="itemes.group" v-model="itemes.checked" @change="changeGroup(index, $event)"></el-checkbox>
                    </div>
                  
                     <div class="twoOne">
                    <div class="threebox" v-for="(item, nn) in itemes.categories">
                    <div class="onerow">
                    <el-checkbox :label="item.categoryName" v-model="item.checked" @change="changeList(index, nn, $event, itemes.categories)"></el-checkbox>
                    </div>

                    <div class="tworow">
                        <template v-for="(its,cn) in item.permissions">
                          <span>
                          <el-tooltip class="item" effect="light" :content="its.description" placement="top">
                          <el-checkbox :label="its.name" v-model="its.checked" @change="changeOne(index, nn, cn, $event, item.permissions)"></el-checkbox>
                         </el-tooltip>
                         </span>
                        </template>
                    </div>
                    </div>
                    
                    </div>
               </div> 
              
           </div>
        </div>   
   </div>    
    <div class="midCss">
          <el-button type="primary" :loading="logining" @click="saveRole" size="medium">提交</el-button>
          <el-button @click="layerClose" size="medium">取 消</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRolePermissions, addRole, editRole } from '@/api/user'
export default {
  name: "PermissionDetail",
  props: ['isShow', 'rowdata'],
  data () {
    return {
      title: "新增角色",
      loading: false,
      kuanflag: false,
      roleOneData: {},
      permissions: [],
      fromArr: {
        name:'',
        remark: ''
      },
      logining: false
    };
  },
   watch: {
    isShow: {
      deep: true,
      handler (newVal, oldVal) {
        console.log(oldVal)
        this.kuanflag = newVal
      }
    },
    rowdata: {
      deep: true,
      handler (newVals, oldVals) {
        console.log(newVals)
        this.roleOneData = newVals
        this.permissions = newVals.permissions
        this.fromArr.name = newVals.name
        this.fromArr.remark = newVals.remark
        if(JSON.stringify(newVals)=='{}'){
          this.getRolePerList()
        }
      }
    }
  },
  methods: {
     layerClose(){
        this.$emit('cancalfunc', false)
     },
     changeOne(index, nn, n, row, datas){
        var that = this;
        console.log(n, row, datas)
        datas[n].checked = row
       
        var num =0;
        that.roleOneData.permissions[index].categories[nn].permissions.map((items)=>{
          if(items.checked){
            num++
          }
        })
        if(that.roleOneData.permissions[index].categories[nn].permissions.length == num){
          // that.roleOneData.permissions[index].checked = true;
          that.roleOneData.permissions[index].categories[nn].checked = true;
        }else{
          // that.roleOneData.permissions[index].checked = false;
          that.roleOneData.permissions[index].categories[nn].checked = false;
        }

         var nums =0
      that.roleOneData.permissions[index].categories.map((itemss)=>{
         if(itemss.checked){
           nums++ 
         }
      })
      if(that.roleOneData.permissions[index].categories.length == nums){
        that.roleOneData.permissions[index].checked = true;
      }else{
        that.roleOneData.permissions[index].checked = false;
      }
        that.$forceUpdate()
     },
     changeList(index, n, row, datas){
      var that = this;
      console.log(n, row, datas)
      datas.checked = row;
      datas[n].permissions.map((item)=>{
          item.checked = row;
      })
      var nums =0
      that.roleOneData.permissions[index].categories.map((itemss)=>{
         if(itemss.checked){
           nums++ 
         }
      })
      if(that.roleOneData.permissions[index].categories.length == nums){
        that.roleOneData.permissions[index].checked = true;
      }else{
        that.roleOneData.permissions[index].checked = false;
      }
      that.$forceUpdate()
     },
     changeGroup(n, row){
        var that = this;
        console.log(n, row)
        that.roleOneData.permissions[n].checked = row;
        that.roleOneData.permissions[n].categories.map((item)=>{
            item.checked = row;
            item.permissions.map((its)=>{
               its.checked = row;
            })
        })
        console.log(that.roleOneData)
        that.$forceUpdate()
     },
     getRolePerList(){
      var that = this
      getRolePermissions().then(res => {
        console.log(res);
        if(res.code ===200) {
          that.permissions = res.data
          that.roleOneData.permissions = res.data

        }else{
          this.$message.error(res.message)
        }
      })
    },
     saveRole(){
        var that = this;
        that.logining = true;
         var objs = {
            name: that.fromArr.name,
            remark: that.fromArr.remark,
            permissions: that.roleOneData?that.roleOneData.permissions: {}
            }
        if(that.roleOneData && that.roleOneData.id){
            
            that.$alert('修改用户权限会触发用户账号被踢下线，需要提醒用户重新登录', '温馨提示', {
              confirmButtonText: '确定',
              callback: action => {
                  objs.id = that.roleOneData.id
                  editRole(objs).then(res => {
                      console.log(res);
                      that.logining = false
                      if(res.code ===200) {
                      that.$message({
                          message: '编辑成功',
                          type: 'success'
                      });
                      that.kuanflag = false;
                      that.$emit('cancalfunc', true)
                      }else{
                      that.$message.error(res.message)
                      }
                  })

               }
            });

        }else{

            addRole(objs).then(res => {
                that.logining = false
                console.log(res);
                if(res.code ===200) {
                that.$message({
                    message: '新增成功',
                    type: 'success'
                });
                that.kuanflag = false;
                that.$emit('cancalfunc', true)
                }else{
                that.$message.error(res.message)
                }
            })
        }
    }
  }
}
</script>

<style scoped>
.rowboxs{
  display: flex;
  overflow: hidden;
  border: 1px solid #eee;
  flex-flow: row;
  border-bottom: none;
}

.rowboxs:hover{ background: #f7f7f7;}
.rowboxs span{
    width: 200px;
    display: inline-block;
    padding-bottom: 10px;
}
.onerow{
    flex: 1;
    display: block;
    border-left: 1px solid #eee;
    padding: 10px;
}
.threeOne{
    flex: 1;
    display: block;
    padding: 10px;
}
.twoOne{
    flex: 6;
    display: block;
}
.threebox{
    display: flex;
    overflow: hidden;
    border-bottom: 1px solid #eee;
    flex-flow: row;
}
.threebox:last-child{
    border-bottom: none;
}
.tworow{
    flex: 5;
    display: block;
    border-left: 1px solid #eee;
    /* border-bottom: 1px solid #eee; */
    padding: 10px;
}
.title {
  background-color: #7a7e8e;
  line-height: 40px;
  margin:0px 0px 0px 0px;
  font-size:15px;
  padding-left:20px;
  color:#fff;
  font-weight: bold;
}
</style>