<template>
  <div>
    <div class="contents">
      <div class="mainbox">
        <el-tabs type="card" v-model="activeName" @tab-click="handleTab">
          
          <el-tab-pane label="企业信息" name="first">
            <br/><br/>
            <el-form ref="searchForm" :model="searchForm" :rules="ruleQi" label-width="120px">
            <el-form-item label="品牌编号" prop="code">
                <span style="color:#61ac85;">{{searchForm.code}}</span>
            </el-form-item>
            <el-form-item label="品牌名称" prop="name">
              <el-input v-model="searchForm.name" clearable  style="width:300px"
                placeholder="请输入品牌名称"></el-input>
            </el-form-item>
            <el-form-item label="品牌logo" prop="logo">
            <el-upload
                class="avatar-uploaderss"
                action="string"
                :http-request="uploadLogo"
                :show-file-list="false"
                :handle-change="handleChange"
                >
                <img v-if="searchForm.logo" :src="searchForm.logo" class="avatarSize">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
             <el-form-item label="联系人" prop="contactName">
              <el-input v-model="searchForm.contactName" clearable  style="width:300px"
                placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="contactPhone">
              <el-input v-model="searchForm.contactPhone" max-length="11" clearable  style="width:300px"
                placeholder="请输入联系电话"></el-input>
            </el-form-item>
             <el-form-item label="联系地址" prop="address">
              <el-input v-model="searchForm.address" clearable  style="width:300px"
                placeholder="请输入联系地址"></el-input>
            </el-form-item>
            <el-form-item label="微信二维码" prop="wechat">
                <el-upload
                class="avatar-uploaderss"
                action="string"
                :http-request="UploadVideo"
                :show-file-list="false"
                :handle-change="handleChange"
                >
                <img v-if="searchForm.wechat" :src="searchForm.wechat" class="avatarSize">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>              
            </el-form-item>
            <el-button @click="saveInfo('searchForm')" type="primary" style="margin-left:120px">保存</el-button>
          </el-form>
          <br/><br/>
          </el-tab-pane>

        <el-tab-pane label="账号管理" name="second">
            <div class="addbtnbox">
              <el-button type="primary" @click="addfun" icon="el-icon-plus">添加用户</el-button>
            </div>
          <el-table
            :data="tableData"
            border
            :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
            style="width: 100%">
             <el-table-column
              label="序号"
          type="index"
          width="50" header-align="center" align="center">
             </el-table-column>
             <el-table-column
              prop="nickName"
              label="昵称"
              width="100"
              >
            </el-table-column>
            <el-table-column
              prop="name"
              label="用户名"
              width="120"
              >
            </el-table-column>
            <el-table-column
              prop="phone"
              label="手机号"
              width="120">
            </el-table-column>  
             <el-table-column
              prop="wxName"
              label="微信号"
              width="100"
              >
            </el-table-column>                   
            <el-table-column
              prop="createTime"
              label="注册时间">
            </el-table-column>
            <el-table-column
              label="账号类型" width="80" header-align="center" align="center">
              <template slot-scope="scope">
                {{ scope.row.administrator?'管理员':'普通' }}
              </template>
            </el-table-column>
            <el-table-column
              label="账号状态" width="80" header-align="center" align="center">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status==0">正常</el-tag>
                <el-tag type="danger" v-if="scope.row.status==2">离职</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              label="角色" header-align="center" align="center">
              <template slot-scope="scope">
               <el-tag
                v-for="tag in scope.row.roles"
                :key="tag.id"
                style="margin-right: 5px"
                >
                {{tag.name}}
              </el-tag>
              </template>
            </el-table-column>
            <el-table-column
          fixed="right"
          label="操作"
          width="160" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row)" v-if="scope.row.status == 0">编辑</el-button>
            <el-popconfirm title="您确定删除吗？" @confirm="deleteUser(scope.row)">
              <el-button style="margin-left:10px; margin-right:10px" type="text" slot="reference" v-if="scope.row.status == 0">删除</el-button>
            </el-popconfirm>
             <el-button type="text" size="small" @click="handleRole(scope.row)" v-if="scope.row.status == 0">设置角色</el-button>
            <!-- <el-button type="text" size="small" @click="handlePass(scope.row)">重置密码</el-button> -->
          </template>
        </el-table-column>
          </el-table>
          <!-- <div class="paginations">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="tableData.length">
          </el-pagination>
          </div> -->
           </el-tab-pane>
       
       <el-tab-pane label="角色权限管理" name="three">

         <div class="addbtnbox">
              <el-button type="primary" @click="addRolefun">添加角色权限</el-button>
            </div>
          <el-table
            :data="roleData"
            border
            :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
            style="width: 100%">
             <el-table-column
              label="序号"
          type="index"
          width="50" header-align="center" align="center">
             </el-table-column>
            
            <el-table-column
              prop="name"
              label="角色名称"
              width="150"
              >
            </el-table-column>
                    
            <el-table-column
              prop="remark"
              label="备注">
            </el-table-column>
             <el-table-column
              prop="createTime"
              label="创建时间">
            </el-table-column>
            <el-table-column
          fixed="right"
          label="操作"
          width="320" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editRoles(scope.row)">编辑权限</el-button>
            <el-popconfirm title="您确定删除吗？" @confirm="deleteRole(scope.row)">
              <el-button style="margin-left:10px" type="text" slot="reference">删除角色</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
          </el-table>
         
       </el-tab-pane>
      
       
        <el-tab-pane label="组织架构" name="five">
          <div class="leftbox">
            <div class="leftTree">
              <div class="leftTreeTitle"><span><el-button type="text" @click="addbumen">添加部门</el-button></span>全部部门</div>
              <el-tree :data="treeData"
                node-key="id"
                :default-expand-all = "false"
                :highlight-current = "true"
                :expand-on-click-node = "false"
                :render-content="renderContent" :props="defaultProps" style="padding: 10px"></el-tree>
            </div>
          </div>
          <div class="rightbox">
          <div class="addbtnbox">
              <el-button type="primary" @click="addemp">添加员工</el-button>
            </div>
         <el-table
            :data="empData"
            border
            :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
            style="width: 100%">
            <el-table-column
              prop="nickName"
              label="姓名"
              >
            </el-table-column>
            <el-table-column
              prop="phone"
              label="手机号"
              width="140">
            </el-table-column>                   
            <el-table-column
              prop="createTime"
              label="注册时间">
            </el-table-column>

            <el-table-column
              label="账号状态" width="80">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status==0">正常</el-tag>
                <el-tag type="danger" v-if="scope.row.status==2">禁用</el-tag>
              </template>
            </el-table-column>

            <el-table-column
              label="员工身份" width="120">
              <template slot-scope="scope">
                {{ scope.row.identity==1?'部门负责人':'部门成员' }}
              </template>
            </el-table-column>
            <el-table-column
          fixed="right"
          label="操作"
          width="100" header-align="center" align="center">
          <template slot-scope="scope">
            <el-popconfirm title="您确定删除吗？" @confirm="handleDel(scope.row)">
              <el-button style="margin-left:10px" type="text" slot="reference">从部门删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
          </el-table>

          </div>

        </el-tab-pane>  
         </el-tabs>
      </div>
    </div>
     <el-dialog :title="titless" :visible.sync="qunVisible" :before-close="handleClose" width="640px">
      <el-form ref="formAdd" :model="formAdd" :rules="ruleShang" label-position="right" label-width="150px"
        style="width:590px;">
        <el-form-item label="员工昵称" prop="nick">
          <el-input v-model="formAdd.nick" autocomplete="off" maxlength="30" placeholder="请输入昵称或姓名"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="name">
          <el-input v-model="formAdd.name" autocomplete="off" maxlength="30" placeholder="请输入姓名"></el-input>
        </el-form-item>
         <el-form-item label="系统登录用户名" prop="username">
          <el-input v-model="formAdd.username" autocomplete="off" maxlength="30" placeholder="请输入用来登录系统的账号名称"></el-input>
        </el-form-item>
        <el-form-item label="系统登录密码" prop="password" v-if="!formAdd.id">
          <el-input v-model="formAdd.password" autocomplete="off" maxlength="30" show-password placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="员工手机号" prop="phone" v-if="!formAdd.id">
          <el-input v-model="formAdd.phone" autocomplete="off" maxlength="11" placeholder="请输入手机号码"></el-input>
        </el-form-item>
         <el-form-item label="短信验证码" prop="code" v-if="!formAdd.id">
          <el-input v-model="formAdd.code" autocomplete="off" maxlength="6" style="width:220px" placeholder="请输入手机短信验证码"></el-input>
          <el-button type="primary" @click="getCodes(formAdd.phone)" class="yancodecode" ref="smsBtn" :disabled="smsBtnAble">
                {{smsBtn}}
          </el-button>
        </el-form-item>

        <el-form-item label="员工微信" style="margin-bottom:10px">
            <el-select v-model="formAdd.botId"  placeholder="请选择机器人微信" style="width:150px" @change="changeAdmin" v-if="!formAdd.wxId">
              <el-option
                v-for="item in groupCode"
                :key="item.id"
                :label="item.name"
                :value="item.botId">
              </el-option>
            </el-select>
            <el-select v-model="formAdd.wxId" filterable clearable placeholder="请选择微信联系人" style="width:200px; margin-left:10px" @change="changeWx">
              <el-option
                v-for="item in dataTree"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        <div class="midCss">
          <el-button type="primary" :loading="logining" @click="handleSubmit('formAdd')" size="medium">提交</el-button>
          <el-button @click="handleClose" size="medium">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>


    <el-dialog title="重置密码" :visible.sync="passVisible" :before-close="handleClosePass" width="600px" style="z-index:9999">
      <el-form ref="formPass" :model="formPass" :rules="ruleShangPass" label-position="right" label-width="120px">
         <el-form-item label="重置用户名">
          <el-input v-model="formPass.username" disabled maxlength="30" placeholder="请输入真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="当前密码" prop="oldpass">
          <el-input v-model="formPass.oldpass" autocomplete="off" show-password placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="pass1">
          <el-input v-model="formPass.pass1" autocomplete="off" show-password placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="pass2">
          <el-input v-model="formPass.pass2" autocomplete="off" show-password placeholder="请再输入一次密码"></el-input>
        </el-form-item>
        <div class="midCss">
          <el-button type="primary" :loading="logining" @click="handleSubmitPass('formPass')" size="medium">确认</el-button>
          <el-button @click="handleClosePass" size="medium">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>

     <el-dialog :title="titless" :visible.sync="empVisible" :before-close="handleClose" width="600px">
      <el-form ref="formAdd" :model="formEmp" label-position="right" label-width="120px"
        style="width:480px;">
         <el-form-item label="选择员工" prop="username">
           <el-select
                v-model="formEmp.employeeId"
                placeholder="选择员工"
                filterable
                clearable
              >
                <el-option
                  v-for="item in tableData"
                  :key="item.id"
                  :label="item.nickName"
                  :value="item.id"
                  :disabled="item.status!=0"
                >
                </el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="员工身份" prop="phone" v-if="!formAdd.id">
           <el-select
                v-model="formEmp.identity"
                placeholder="请选择员工身份"
              >
                <el-option
                  v-for="item in empArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
        </el-form-item>
        <div class="midCss">
          <el-button type="primary" :loading="logining" @click="addEmployees('formEmp')" size="medium">提交</el-button>
          <el-button @click="handleClose" size="medium">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      :title="orgTitle"
      :visible.sync="bumendialogVisible"
      width="30%"
      :before-close="bumenhandleClose">
      <span><el-input v-model="orgname" maxlength="30" placeholder="请输入部门名称"></el-input></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bumendialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addbumenFun">确 定</el-button>
      </span>
    </el-dialog>

     <el-dialog title="设置用户角色" :visible.sync="roleVisible" :before-close="handleClose" width="600px">
      <el-form ref="formRole" :model="formRole" label-position="right" label-width="120px"
        style="width:480px;">
         <el-form-item label="选择角色" prop="username">
           <el-select
                v-model="formRole.roleIds"
                placeholder="选择角色"
                 multiple
                filterable
                clearable
              >
                <el-option
                  v-for="item in roleData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <div style="font-size:12px;color:red;line-height:20px;margin-top:10px;">用户具有的权限是所有角色权限的集合。只要有任意一个所选角色具备某项权限，则该角色对应的用户就具备该项权限。例如：角色A具备订单查看权限，角色B不具备此权限，当用户同时具有角色A和角色B时，用户会继承A角色的订单查看权限。</div>
        </el-form-item>
      </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="roleVisible = false">取 消</el-button>
        <el-button type="primary" @click="modifyRoleFun">确 定</el-button>
      </span>
     </el-dialog>

   
    <permission :isShow="roleFlag" :rowdata="roleOneData"  @cancalfunc="cancalFuns"></permission>
  </div>
</template>

<script>
import { getUserList, addUser, delUser, editUser, editTeams,removeTree, chakanTeams, addEmployee,removeEmployee, organizationTree,getTreeMember, organizationEmployee, getRole, changeUserRole, delRole, addOrg, editOrg} from "@/api/user";
import { modiflyPassWord, sendMsg, logout } from '@/api/login'
import { getBotList,contactFun} from "@/api/robot";
import permission from "@/components/permission"
import {uploadFile} from "@/api/common"

export default {
  name: 'Teams',
  components: { permission },
  data() {
    return {
      formPass: {
        username: '',
        oldpass: '',
        pass1: '',
        pass2: ''
      },
      searchForm: {
        name: '',
        contactPhone: '',
        contactName: '',
        address: '',
        wechat: '',
        logo: '',
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      formEmp: {
        departmentId: '',
        employeeId: '',
        identity: ''
      },
      empData: [],
      roleData: [],
      empArr: [{id:'0', name:'部门成员'}, {id:'1', name:'部门负责人'}],
      activeName: 'first',
      titless: '添加用户账号',
      orgTitle: '创建新部门',
      bumendialogVisible: false,
      empVisible: false,
      orgname: '',
      objorgs: '',
      tableData: [],
      logining: false,
      ruleShang: {
        nick: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        name: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
        phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur"
          },
          {
            validator: function(rule, value, callback) {
              if (/^1[3456789]\d{9}$/.test(value) == false) {
                callback(new Error("手机号格式错误"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      },
      ruleShangPass: {
        oldpass: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        pass1: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        pass2: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: this.validatePass2, trigger: 'blur' }
        ]
      },
      ruleQi: {
        name: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
        contactPhone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        contactName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        address: [{ required: true, message: '请输入联系地址', trigger: 'blur' }],
      },
      groupCode: [],
      dataTree: [],
      passVisible: false,
      qunVisible: false,
      smsBtnAble: false,
      smsBtn: '发送验证码',
      formAdd: {
        id: '',
        nick: '',
        password: '',
        phone: '',
        username: '',
        code: '',
        botId: '',
        wxId: '',
        wxName: '',
      },
      roleFlag: false,
      roleOneData: [],
      roleVisible: false,
      formRole: {
        roleIds: [],
        id: ''
      }

    }
  },
  mounted(){
    this.getTeams()
    this.getUser()
  },
  methods: {
    getCodes(phone){
      console.log(phone)
      var that = this
      var objs = {}
      objs.phoneNumbers = phone
      if(phone==''){
        that.$message.error('请输入手机号码')
        return
      }
      sendMsg(objs).then(res => {
        console.log(res);
        if(res.code == 200){
          that.$message({
              type: 'success',
              message: '短信验证码发送成功'
          })
          let time = 60
          that.smsBtnAble = true
          let a = setInterval(() => {
            time--
            this.smsBtn = `${time}s后可再次获取`
            // this.$refs.smsBtn.$el.style = 'background:#0f0b00b4'
            if (time == 0) {
              clearInterval(a)
              // this.$refs.smsBtn.$el.style = 'background:#0f0b00'
              that.smsBtnAble = false
              that.smsBtn = '重新发送验证码'
            }
          }, 1000)
        }else{
          that.$message.error(res.message)
        }
      })
    },
    validatePass2 (rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.formPass.pass1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },
    UploadVideo(param){
      var that = this
      const formData = new FormData()
      formData.append('file', param.file)
      uploadFile(formData).then(res => {
      	console.log('上传图片成功')
        // param.onSuccess()  // 上传成功的图片会显示绿色的对勾
        that.searchForm.wechat = res.data.url
        that.searchForm.wechatBar = res.data.url
        that.searchForm.contactName = that.searchForm.contactName + " " 
        that.searchForm.contactName = that.searchForm.contactName.trim()
      }).catch(response => {
        console.log('图片上传失败')
        param.onError()
      })
    },
    uploadLogo(param){
      var that = this
      const formData = new FormData()
      formData.append('file', param.file)
      uploadFile(formData).then(res => {
      	console.log('上传图片成功')
        that.searchForm.logo = res.data.url
      }).catch(response => {
        console.log('图片上传失败')
        param.onError()
      })
    },
    handleChange(file, fileList){
      if(fileList.length > 1) {
        fileList.splice(0,1)
      }
    },
    handleTab(tab){
      console.log(tab)
      if(tab.name == 'first'){
        this.getUser()
      }
      if(tab.name == 'second'){
        this.getTeams()
      }
      if(tab.name == 'three'){
        this.getRoleList()
      }
       if(tab.name == 'five'){
        this.getOrganizationTree()
      }
    },
    addemp(){
      this.empVisible = true
    },
    addEmployees(){
      var that = this
      if(!that.formEmp.departmentId){
        that.$message.error('请先在左边组织架构里面选择部门')
        return false
      }
      addEmployee(that.formEmp).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.$message({
            message: '创建成功',
            type: 'success'
          });
          that.empVisible = false;
          that.getOrganizationEmy(that.formEmp.departmentId) 


        }else{
          that.$message.error(res.message)
        }
      })
    },
    getTeams(){
      var that = this
      chakanTeams().then(res => {
        console.log(res);
        if(res.code ===200) {
          that.searchForm = res.data
          that.searchForm.wechat = res.data.wechatBar
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getOrganizationTree(){
      var that = this
      organizationTree().then(res => {
        console.log(res);
        if(res.code ===200) {
          that.treeData = res.data;
          that.empData = res.data ? res.data[0].member : [];
          that.formEmp.departmentId = res.data[0].id
        }else{
          this.$message.error(res.message)
        }
      })
    },
    addbumen(e){
      console.log(e)
      this.bumendialogVisible = true;
      this.objorgs = e?e:'';
      this.orgname = '';
      this.orgTitle = '添加部门'
    },
    addbumenFun(e){
      var that = this
      let objs = {
        "code": that.objorgs ? that.objorgs.code : '0',
        "name": that.orgname,
        "parentDepartmentId": that.objorgs ? that.objorgs.id : '0'
      }
      let objedit = {
        "code": that.objorgs ? that.objorgs.code : '0',
        "id": that.objorgs ? that.objorgs.id : '0',
        "name": that.orgname,
        "parentDepartmentId": that.objorgs ? that.objorgs.parentId : '0'
      }
      if(that.orgTitle=='编辑部门'){
        editOrg(objedit).then(res => {
          console.log(res);
          if(res.code ===200) {
            that.$message({
              message: '编辑成功',
              type: 'success'
            });
            that.bumendialogVisible = false;
            that.getOrganizationTree()
          }else{
            that.$message.error(res.message)
          }
        })
      }else{
        addOrg(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.$message({
            message: '新增成功',
            type: 'success'
          });
          that.bumendialogVisible = false;
          that.getOrganizationTree()
        }else{
          that.$message.error(res.message)
        }
      })
      }
    },
    getOrganizationEmy(ids){
      var that = this
      var objs = {
        id: ids
      }
      getTreeMember(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.empData = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleNodeClick(n, e){
      console.log(e)
      var that = this
      // that.empData = e.member
      that.formEmp.departmentId = e.id
      that.getOrganizationEmy(e.id) 
    },
    saveInfo(formName){
      let that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.logining = true
          editTeams(that.searchForm).then(res => {
            that.logining = false
            if (parseInt(res.code) === 200) {
                that.$message({
                    message: '修改成功',
                    type: 'success'
                  });
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    handleClose(){
      this.qunVisible = false
      this.empVisible = false
      this.roleVisible = false
    },
    handleClosePass(){
      this.passVisible = false
    },
    getUser(){
      var that = this
      getUserList({}).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tableData = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleEdit(row){
      var that = this;
      that.getBodList();
      that.titless = '编辑用户'
      that.qunVisible = true
      that.formAdd.id = row.id
      that.formAdd.nick = row.nickName
      that.formAdd.password = row.password
      that.formAdd.phone = row.phone
      that.formAdd.username = row.name
      that.formAdd.wxId = row.wxId
      that.formAdd.wxName = row.wxName
      that.formAdd.botId = row.botId
    },
     getBodList(){
      var that = this
      var datas = {}
      datas.pageNo = 1;
      datas.pageSize = 100;
      datas.orderByCreated = "DESC";
      getBotList(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.groupCode = res.data.rows
          that.getContact(that.groupCode[0].botId)
         
        }else{
          this.$message.error(res.message)
        }
      })
    },
    changeAdmin(e){
      console.log(e)
      var that = this
      that.formAdd.botId = e
      that.getContact(e)
    },
    changeWx(e){
      console.log(e)
      var that = this
      that.dataTree.map((item)=>{
        if(item.id == e){
          that.formAdd.wxName = item.name
          that.formAdd.wxId = item.id
        }
      })

    },
    getContact(botId){
      var that = this
      var datas = {
        botId: botId
      }
      contactFun(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.dataTree = res.data.contacts
        }else{
          that.$message.error(res.message)
        }
      })
    },
    addfun(){
      var that = this;
      that.qunVisible = true
      that.formAdd.id = ''
      that.formAdd.nick = ''
      that.formAdd.password = ''
      that.formAdd.phone = ''
      that.formAdd.username = ''
      that.formAdd.wxId = ''
      that.formAdd.wxName = ''
      that.formAdd.botId = ''
      that.getBodList()
    },
    handlePass(row){
      var that = this;
      that.passVisible = true
      console.log(row)
      that.formPass.username = row.name
      that.formPass.id = row.id
    },
    handleSubmitPass(formName){
      let that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.logining = true
          modiflyPassWord(that.formPass).then(res => {
                that.logining = false
                if (parseInt(res.code) === 200) {
                    that.$message({
                      message: '重置密码成功',
                      type: 'success'
                    });
                    setTimeout(() => {
                      that.passVisible = false
                    }, 500)
                
                } else {
                  this.$message.error(res.message)
                }
          })
        }
      })
    },
    handleDel(row){
      var that = this
      var objs = {}
      objs.departmentId = that.formEmp.departmentId
      objs.employeeId = row.id
      removeEmployee(objs).then(res => {
        if(res.code ===200){
          that.$message({
            message: '删除成功',
            type: 'success'
          });
          that.getOrganizationEmy(that.formEmp.departmentId) 
        }else{
          that.$message.error(res.message)
        }
      })
    },
    deleteUser(row){
      var that = this
      var objs = {}
      objs.id = row.id
      delUser(objs).then(res => {
        if(res.code ===200){
          that.$message({
            message: '删除成功',
            type: 'success'
          });
          that.getUser()
        }else{
          that.$message.error(res.message)
        }
      })
    },
    handleDelTree(row){
      var that = this
      var objs = {}
      objs.id = row.id
      removeTree(objs).then(res => {
        if(res.code ===200){
          that.$message({
            message: '删除成功',
            type: 'success'
          });
          that.getOrganizationTree()
        }else{
          that.$message.error(res.message)
        }
      })
    },
    renderContent(h, { node, data, store }) {
      return (
        <div style="font-size: 13px; line-height:30px; width:100%; overflow:hidden">
          <span style="float: right;">
            <el-button size="mini" type="text" on-click={ () => this.addbumen(data) }><i class="el-icon-plus"></i></el-button>
            <el-button size="mini" type="text" on-click={ () => this.editFuns(data) }><i class="el-icon-edit"></i></el-button>
            <el-popconfirm title="您确定删除吗？" on-confirm={ () => this.handleDelTree(data) }>
              <el-button style="margin-left:10px" type="text" slot="reference"><i class="el-icon-delete"></i></el-button>
            </el-popconfirm>
          </span>
          <bdo on-click={()=> this.handleNodeClick(node, data)}>{node.label}</bdo>
        </div>);
    },
    editFuns(e){
      console.log(e)
      this.bumendialogVisible = true;
      this.objorgs = e?e:'';
      this.orgname = e.name;
      this.orgTitle = '编辑部门'
    },
    bumenhandleClose(e){
      this.bumendialogVisible = false;
    },
    handleSubmit(formName){
      let that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.logining = true
          if(that.formAdd.id){
            editUser(that.formAdd).then(res => {
              that.logining = false
              if (parseInt(res.code) === 200) {
                   that.$message({
                      message: '修改成功',
                      type: 'success'
                    });
                  setTimeout(() => {
                    that.qunVisible = false
                  }, 500)
                  that.getUser()
              } else {
                this.$message.error(res.message)
              }
            })

          }else{
            addUser(that.formAdd).then(res => {
              that.logining = false
              if (parseInt(res.code) === 200) {
                 that.$message({
                      message: '新增成功',
                      type: 'success'
                    });
                  setTimeout(() => {
                    that.qunVisible = false
                  }, 500)
                  that.getUser()
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    },
    getRoleList(){
      var that = this
      getRole().then(res => {
        console.log(res);
        if(res.code ===200) {
          that.roleData = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    deleteRole(row){
      var that = this
      var objs = {}
      objs.id = row.id
      delRole(objs).then(res => {
        if(res.code ===200){
          that.$message({
            message: '删除成功',
            type: 'success'
          });
          that.getRoleList()
        }else{
          that.$message.error(res.message)
        }
      })
    },
    addRolefun(){
      this.roleFlag = true;
      this.roleOneData = {};
    },
    editRoles(row){
      console.log(row)
      this.roleFlag = true;
      this.roleOneData = row;
    },
    cancalFuns(e){
      if(e){
       this.getRoleList()
       this.roleFlag = !e;
      }else{
       this.roleFlag = e;
      }
    },
    modifyRoleFun(){
      var that = this
      console.log(that.formRole)
      changeUserRole(that.formRole).then(res => {
        if(res.code ===200){
          that.$message({
            message: '修改角色成功',
            type: 'success'
          });
          that.roleVisible = false
          that.getUser()
        }else{
          that.$message.error(res.message)
        }
      })
    },
    handleRole(row){
      this.formRole.id = row.id;
      var rowrole = row.roles || [];
      var rowids = []
      rowrole.map((its)=>{
        rowids.push(its.id)
      })
      this.formRole.roleIds = rowids;
      this.roleVisible = true;
      this.getRoleList();
    }
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}

.addbtnbox{
  display: block;
  padding-bottom: 10px;
  text-align:right
}
.leftbox{
  float: left;
  width: 26%;
}
.leftTree{
  border: 1px solid #eee;
  display: block;
  min-height:600px;
}
.leftTreeTitle{
  height: 40px;
  line-height: 40px;
  color: #fff;
  display: block;
  text-align: left;
  background-color: #07C160;
  font-weight: bold;
  font-size: 16px;
  padding: 0 20px;
}
.leftTreeTitle span{
  float: right
}

.leftTreeTitle .el-button--text{
  color: #FFF;
}
.leftTree ul{
  padding: 10px;
  overflow: hidden;
}
.leftTree ul li{
  margin: 0;
  padding: 0;
  list-style: none;
  line-height:44px;
  font-size: 16px;
  color: #333;
  border-bottom: 1px solid #eee;
}
.rightbox{
  float: right;
  width: 72%;
}

.el-tree-node__content{
  height: 30px;
}
.yancodecode{
  display: inline-block;
  padding: 3px 20px;
  width: 120px;
  text-align: center;
  background-color: #f3f3f3;
  color: #535353;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 20px;
  border: none;
  font-size: 12px;
  line-height:30px;
}
</style>